import {useFetcher} from "@remix-run/react"
import clsx from "clsx"
import {useRef, useState} from "react"

import {useGA4Events} from "~/hooks/use-ga4-events"
import {CartLineItem} from "~/types/cart"
import {getProductQtyOptions} from "~/utilities/product"

import {Input} from "../elements/Input"
import {Select} from "../elements/Select"

type UpdateCartInputProps = {
  lineItem: CartLineItem
  showDeleteOption?: boolean
}

export const UpdateCartInput = ({
  lineItem,
  showDeleteOption,
}: UpdateCartInputProps) => {
  const [newQuantity, setNewQuantity] = useState(lineItem.quantity.toString())
  const fetcher = useFetcher()

  const [showQtyInput, setShowQtyInput] = useState(lineItem.quantity > 14)

  const inputRef = useRef<HTMLInputElement>(null)

  const {removeFromCart} = useGA4Events()

  const updateCart = (newQuantity: number) => {
    fetcher.submit(
      {
        cartAction: "UPDATE_CART",
        lines: JSON.stringify({
          id: lineItem.id,
          quantity: newQuantity,
        }),
      },
      {
        action: "/cart",
        method: "post",
      },
    )

    if (!newQuantity) {
      removeFromCart({
        ...lineItem.merchandise.product,
        quantity: lineItem.quantity,
        variants: {
          edges: [
            {
              node: {
                price: {amount: lineItem.cost.amountPerQuantity.amount},
              },
            },
          ],
        },
      })
    }
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    if (value === "manual") {
      setShowQtyInput(true)
      setTimeout(() => inputRef.current?.select(), 0)
    } else {
      updateCart(parseInt(value, 10))
      setNewQuantity(value)
    }
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value, max} = event.target
    if (!value) {
      setNewQuantity(value)
      return
    }
    const parsedValue = parseInt(value, 10)
    if (isNaN(parsedValue)) {
      return
    }
    if (!max) {
      setNewQuantity(value)
    } else {
      setNewQuantity(parsedValue > parseInt(max, 10) ? max : value)
    }
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (newQuantity !== lineItem.quantity.toString()) {
      updateCart(parseInt(event.target.value || "0", 10))
    }
  }

  return (
    <>
      <Select
        name="quantity"
        id="quantity"
        value={newQuantity}
        onChange={handleSelectChange}
        className={clsx(
          "min-w-[100px] max-w-[150px] w-full",
          showQtyInput && "hidden",
        )}
      >
        {!!showDeleteOption && <option value={0}>0 - Remove</option>}
        {getProductQtyOptions(14)}
        <option value="manual">15+</option>
      </Select>
      <Input
        type="number"
        max="1000"
        containerClassName={clsx(!showQtyInput && "hidden")}
        className={"min-w-[100px] max-w-[150px] w-full"}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        value={newQuantity}
        ref={inputRef}
      />
    </>
  )
}
