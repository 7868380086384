import {useRootLoaderData} from "./use-root-loader-data"

type GA4Product = {
  id: string
  title: string
  vendor: string
  variants: {
    edges: Array<{
      node: {
        price: {amount: string}
      }
    }>
  }
}

type GA4LineItem = GA4Product & {
  quantity: number
}

export const useGA4Events = () => {
  const {app, cookieConsent} = useRootLoaderData()
  const ignore = app.isDevelopment || !cookieConsent.analytics

  const pageView = () => {
    if (ignore || typeof window.gtag == "undefined") {
      return
    }
    window.gtag("event", "page_view")
  }

  const viewItem = <T extends GA4Product>(product: T) => {
    if (ignore || typeof window.gtag == "undefined") {
      return
    }
    const item = formatGAItem(product)
    window.gtag("event", "view_item", {
      currency: "USD",
      value: item.price,
      items: [item],
    })
  }

  const viewCart = <T extends GA4LineItem>(products: T[], total: number) => {
    if (ignore || typeof window.gtag == "undefined") {
      return
    }
    const items = products.map(formatGAItem)
    window.gtag("event", "view_cart", {
      currency: "USD",
      value: total,
      items,
    })
  }

  const addToCart = <T extends GA4LineItem>(product: T) => {
    if (ignore || typeof window.gtag == "undefined") {
      return
    }
    const item = formatGAItem(product)
    window.gtag("event", "add_to_cart", {
      currency: "USD",
      value: parseFloat((item.price * (item.quantity ?? 1)).toFixed(2)),
      items: [item],
    })
  }

  const removeFromCart = <T extends GA4LineItem>(product: T) => {
    if (ignore || typeof window.gtag == "undefined") {
      return
    }
    const item = formatGAItem(product)
    window.gtag("event", "remove_from_cart", {
      currency: "USD",
      value: parseFloat((item.price * (item.quantity ?? 1)).toFixed(2)),
      items: [item],
    })
  }

  const search = (query: string) => {
    if (ignore || typeof window.gtag == "undefined") {
      return
    }
    window.gtag("event", "search", {
      search_term: query,
    })
  }

  return {
    pageView,
    viewItem,
    viewCart,
    addToCart,
    removeFromCart,
    search,
  }
}

const formatGAItem = <T extends GA4Product | GA4LineItem>(item: T) => {
  const {id, title, vendor, variants} = item
  return {
    item_id: id.replace("gid://shopify/Product/", ""),
    item_name: title,
    item_brand: vendor,
    price: parseFloat(variants.edges[0].node.price.amount),
    quantity: "quantity" in item ? item.quantity : 1,
  }
}
