import {ProductCollectionSortKeys, ProductsQueryVariables} from "~/graphql"
import {ProductSortParam} from "~/types/product"
import {getPagingValues} from "~/utilities/paging"

import {
  COLLECTION_MAIN_TAG_PREFIX,
  COLLECTION_TAG_PREFIX,
} from "./collection-tag"

type GetProductsQueryVariablesArgs = {
  search?: string
  collectionHandle: string
  searchParams: URLSearchParams
  pageSize: number
}

export const getProductsQueryVariables = ({
  search,
  collectionHandle,
  searchParams,
  pageSize,
}: GetProductsQueryVariablesArgs) => {
  const sortedFilterParams = Array.from(searchParams.entries())
    .filter(([key]) => key.startsWith("filter_"))
    .map(([key, value]) => [key, value.replaceAll("+", " ")])
    .sort((a, b) => {
      if (a[0] == b[0]) {
        return a[1] > b[1] ? 1 : -1
      }
      return a[0] > b[0] ? 1 : -1
    })

  const appliedFilters = sortedFilterParams.reduce((acc, [key, value]) => {
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(value)

    return acc
  }, {} as Record<string, string[]>)

  const query: string[] = []

  if (collectionHandle !== "all") {
    query.push(
      `tag:'${COLLECTION_MAIN_TAG_PREFIX}${collectionHandle}' OR tag:'${COLLECTION_TAG_PREFIX}${collectionHandle}'`,
    )
  } else if (search) {
    query.push(search)
  }

  const appliedFilterEntries = Object.entries(appliedFilters)

  for (const [key, values] of appliedFilterEntries) {
    query.push(
      `(${values.map((value) => `tag:'${key}_${value}'`).join(" OR ")})`,
    )
  }

  // Only include subcollection if one filter per filter criteria
  const includeSubcollection =
    collectionHandle !== "all" &&
    !!appliedFilterEntries.length &&
    appliedFilterEntries.every((entry) => entry[1].length === 1)

  const useProductsQuery = !!search || !!appliedFilterEntries.length

  const variables: ProductsQueryVariables = {
    collectionHandle,
    includeSubcollection,
    useProductsQuery,
    subcollectionHandle: [
      collectionHandle,
      ...sortedFilterParams.map((param) => param[1]),
    ]
      .join("-")
      .replaceAll(" ", "-")
      .toLowerCase(),
    query: query.join(" AND "),
    ...getSortValues(searchParams, useProductsQuery, !!search),
    ...getPagingValues(searchParams, pageSize),
  }

  return {
    sortedFilterParams,
    includeSubcollection,
    appliedFilters,
    variables,
  }
}

export const getSortValues = (
  searchParams: URLSearchParams,
  useProductsQuery: boolean,
  isSearch: boolean,
) => {
  const key = useProductsQuery ? "productSortKey" : "productCollectionSortKey"

  switch (searchParams.get("sort") as ProductSortParam) {
    case "price-high-low":
      return {
        [key]: ProductCollectionSortKeys.Price,
        reverse: true,
      }
    case "price-low-high":
      return {
        [key]: ProductCollectionSortKeys.Price,
        reverse: false,
      }
    case "best-selling":
      return {
        [key]: ProductCollectionSortKeys.BestSelling,
        reverse: false,
      }
    case "relevance":
      return {
        [key]: ProductCollectionSortKeys.Relevance,
        reverse: false,
      }
    default:
      return {
        [key]: useProductsQuery
          ? isSearch
            ? ProductCollectionSortKeys.Relevance
            : ProductCollectionSortKeys.BestSelling
          : ProductCollectionSortKeys.Manual,
        reverse: false,
      }
  }
}

export const getProductQtyOptions = (maxQty: number): JSX.Element[] => {
  return Array.from({length: maxQty}, (_, index) => (
    <option key={index + 1} value={index + 1}>
      {index + 1}
    </option>
  ))
}
